

















































































































































import {insertJs} from '@/utils';
import {Component, Vue} from 'vue-property-decorator';
@Component({
  metaInfo: {
    title: 'How much Sleep have Americans lost during COVID-19? | Ocere',
    meta: [
      {
        name: 'description',
        hid: 'description',
        content:
          'How much sleep have Americans lost during the COVID-19 Pandemic? Click here to learn which state in the USA has occurred the biggest sleep debt to date.',
      },
    ],
  },
})
export default class extends Vue {
  mapData: any = {
    colours: {
      highlightFillColor: '#FF9F1A',
      highlightBorderColor: '#fff',
      borderColor: '#ffffff',
      defaultFill: '#2EC2B3',
    },

    AK: {
      name: 'Alaska',
      inhabitant: 'Alaskans ',
      statistic_1: '20.2',
    },
    AR: {
      name: 'Arkansas',
      inhabitant: ' Arkansans',
      statistic_1: '12',
    },
    AL: {
      name: 'Alabama',
      inhabitant: 'Alabamians',
      statistic_1: '18.2',
    },
    AZ: {
      name: 'Arizona',
      inhabitant: 'Arizonans ',
      statistic_1: '24.9',
    },
    CA: {
      name: 'California',
      inhabitant: 'Californians ',
      statistic_1: '20.9',
    },
    CT: {
      name: 'Connecticut',
      inhabitant: 'Connecticuters ',
      statistic_1: '19.3',
    },
    CO: {
      name: 'Colorado',
      inhabitant: 'Coloradans ',
      statistic_1: '17.1',
    },
    DE: {
      name: 'Delaware',
      inhabitant: 'Delawareans ',
      statistic_1: '15',
    },
    FL: {
      name: 'Florida',
      inhabitant: 'Floridians ',
      statistic_1: '22.1',
    },
    GA: {
      name: 'Georgia',
      inhabitant: 'Georgians ',
      statistic_1: '18.6',
    },
    HI: {
      name: 'Hawaii',
      inhabitant: 'Hawaii residents ',
      statistic_1: '20.4',
    },
    ID: {
      name: 'Idaho',
      inhabitant: 'Idahoans ',
      statistic_1: '26.7',
    },
    IL: {
      name: 'Illinois',
      inhabitant: 'Illinoisans ',
      statistic_1: '17.8',
    },
    IN: {
      name: 'Indiana',
      inhabitant: 'Hoosiers ',
      statistic_1: '19.5',
    },
    IA: {
      name: 'Iowa',
      inhabitant: 'Iowans ',
      statistic_1: '20.5',
    },
    KS: {
      name: 'Kansas',
      inhabitant: 'Kansans ',
      statistic_1: '24',
    },
    KY: {
      name: 'Kentucky',
      inhabitant: 'Kentuckians ',
      statistic_1: '19.7',
    },
    LA: {
      name: ' Louisiana',
      inhabitant: 'Louisianans ',
      statistic_1: '25.2',
    },
    MD: {
      name: 'Maryland',
      inhabitant: 'Marylanders ',
      statistic_1: '24.5',
    },
    ME: {
      name: 'Maine',
      inhabitant: 'Mainers ',
      statistic_1: '23.1',
    },
    MA: {
      name: 'Massachusetts',
      inhabitant: 'Bay Staters ',
      statistic_1: '19.5',
    },
    MN: {
      name: 'Minnesota',
      inhabitant: 'Minnesotans ',
      statistic_1: '20.2',
    },
    MI: {
      name: 'Michigan',
      inhabitant: 'Michiganders ',
      statistic_1: '23.6',
    },
    MS: {
      name: 'Mississippi',
      inhabitant: 'Mississippians ',
      statistic_1: '29.5',
    },
    MO: {
      name: 'Missouri',
      inhabitant: 'Missourians ',
      statistic_1: '17.3',
    },
    MT: {
      name: 'Montana',
      inhabitant: 'Montanans ',
      statistic_1: '21',
    },
    NC: {
      name: 'North Carolina',
      inhabitant: 'North Carolinians ',
      statistic_1: '22.3',
    },
    NE: {
      name: 'Nebraska',
      inhabitant: 'Nebraskans ',
      statistic_1: '25.6',
    },
    NV: {
      name: 'Nevada',
      inhabitant: 'Nevadans ',
      statistic_1: '24.6',
    },
    NH: {
      name: 'New Hampshire',
      inhabitant: 'New Hampshirites ',
      statistic_1: '10.5',
    },
    NJ: {
      name: 'New Jersey',
      inhabitant: 'New Jerseyans ',
      statistic_1: '20.8',
    },
    NY: {
      name: 'New York',
      inhabitant: 'New Yorkers ',
      statistic_1: '25.1',
    },
    ND: {
      name: 'North Dakota',
      inhabitant: 'North Dakotans ',
      statistic_1: '12.9',
    },
    NM: {
      name: 'New Mexico',
      inhabitant: 'New Mexicans ',
      statistic_1: '23.8',
    },
    OH: {
      name: 'Ohio',
      inhabitant: 'Ohioans ',
      statistic_1: '20.6',
    },
    OK: {
      name: 'Oklahoma',
      inhabitant: 'Oklahomans ',
      statistic_1: '17.7',
    },
    OR: {
      name: 'Oregon',
      inhabitant: 'Oregonians ',
      statistic_1: '20.3',
    },
    PA: {
      name: 'Pennsylvania',
      inhabitant: 'Pennsylvanians ',
      statistic_1: '19.5',
    },
    RI: {
      name: 'Rhode Island',
      inhabitant: 'Rhode Islanders ',
      statistic_1: '13.5',
    },
    SC: {
      name: 'South Carolina',
      inhabitant: 'South Carolinians ',
      statistic_1: '24.5',
    },
    SD: {
      name: 'South Dakota',
      inhabitant: 'South Dakotans ',
      statistic_1: '21.2',
    },
    TN: {
      name: 'Tennessee',
      inhabitant: 'Tennesseans ',
      statistic_1: '20.7',
    },
    TX: {
      name: 'Texas',
      inhabitant: 'Texans ',
      statistic_1: '20.9',
    },
    UT: {
      name: 'Utah',
      inhabitant: 'Utahns ',
      statistic_1: '13.4',
    },

    VA: {
      name: 'Virginia',
      inhabitant: 'Virginians ',
      statistic_1: '19.9',
    },
    VT: {
      name: 'Vermont',
      inhabitant: 'Vermonters ',
      statistic_1: '26.6',
    },
    WA: {
      name: 'Washington',
      inhabitant: 'Washingtonians ',
      statistic_1: '25.1',
    },
    WV: {
      name: 'West Virginia',
      inhabitant: 'West Virginians ',
      statistic_1: '14',
    },
    WI: {
      name: 'Wisconsin',
      inhabitant: 'Wisconsinites ',
      statistic_1: '21.1',
    },
    WY: {
      name: 'Wyoming',
      inhabitant: 'Wyomingites ',
      statistic_1: '22.4',
    },
  };
  projectSettings: any = {
    /* Your company name. */

    brandTitle: 'Ocere',

    /* The URL to your homepage */

    brandUrl: 'https://www.ocere.com/',

    /* The source URL of the index.html file, used by the <iframe> */

    sourceUrl: 'https://www.ocere.com/content/sleep-infographic/',

    /* A URL to a page on your website where the infographic can be viewed. */

    fullSizeUrl: 'https://www.ocere.com/content/sleep-infographic',

    /* If you wish to track activity with Google Analytics, enter your Analytics ID here. E.g UA-123456789-1*/

    google_analytics: 'UA-XXXXXXXXXXXXXXXXXX',

    /* The height of the embedded iframe. */

    frameHeight: 800,

    /* The width of the embedded iframe. */

    frameWidth: '100%',
  };
  addthisShare = {
    pub_id: 'ra-5dd3b9ce0f4c7b79',
    url: this.projectSettings.fullSizeUrl,
  };

  created() {
    window.addEventListener('message', (t: any) => {
      if ('FrameHeight' === t.data) {
        /* tslint:disable:one-variable-per-declaration */
        const a = document.body,
          i = document.documentElement;
        let e = Math.max(
          a.scrollHeight,
          a.offsetHeight,
          i.clientHeight,
          i.scrollHeight,
          i.offsetHeight
        );
        (e += 20),
          t.source.postMessage(
            {
              FrameHeight: e,
            },
            '*'
          );
      }
    });
  }
  mounted() {
    insertJs(
      'script',
      [
        '/static/js/sleep-infographic/settings.js',
        '/static/js/sleep-infographic/vendor.js',
        '/static/js/sleep-infographic/plugins.js',
      ],
      () => {
        if (this.addthisShare.pub_id) {
          const script: any = document.createElement('script');
          /* tslint:disable:no-empty */
          script.onload = function () {};
          script.src =
            '//s7.addthis.com/js/300/addthis_widget.js#pubid=' +
            this.addthisShare.pub_id;
          document.head.appendChild(script);
        }

        if (this.projectSettings.google_analytics.match('UA-')) {
          // Google Analytics: change UA-XXXXX-X to be your site's ID.
          (function (b, o, i, l) {
            // @ts-ignore
            b.GoogleAnalyticsObject = l;
            b[l] ||
              (b[l] = function () {
                (b[l].q = b[l].q || []).push(arguments);
              });
            b[l].l = +new Date();
            const e: any = o.createElement(i);
            const r: any = o.getElementsByTagName(i)[0];
            e.src = 'https://www.google-analytics.com/analytics.js';
            r.parentNode.insertBefore(e, r);
          })(window, document, 'script', 'ga');
          // @ts-ignore
          ga('create', this.projectSettings.google_analytics);
          // @ts-ignore
          ga('send', 'pageview');
        }

        this.buildMap();
        this.embedThis();
        this.$nextTick(() => {
          const newh: any = $('.datamap').attr('height');
          const neww: any = $('.datamap').attr('width');
          $('.sleep-infographic svg').attr(
            'viewBox',
            '0 0 ' + (neww - 40) + ' ' + (newh - 40) + ''
          );
          $('#map path').on('mouseover touch', () => {
            $('.datamaps-hoverover').addClass('active');
            $('body').addClass('data-active');
          });
          $('#map path').on('mouseleave', () => {
            $('.datamaps-hoverover').removeClass('active');
            $('body').removeClass('data-active');
          });
          $('.data-active').on('mouseover touch', () => {
            $('.datamaps-hoverover').removeClass('active');
            $('body').removeClass('data-active');
          });
        });
      }
    );
  }

  buildMap() {
    if (void 0 === this.mapData) {
      return console.log('Could not load map data.'), !1;
    }
    if (void 0 === this.mapData.colours) {
      return console.log('Colours for the map have not been defined.'), !1;
    }
    // @ts-ignore
    const t = new Datamap({
      scope: 'usa',
      element: document.getElementById('map'),
      geographyConfig: {
        popupOnHover: !0,
        highlightOnHover: !0,
        highlightFillColor: this.mapData.colours.highlightFillColor,
        highlightBorderOpacity: 1,
        highlightBorderWidth: 1,
        highlightBorderColor: this.mapData.colours.highlightBorderColor,
        borderColor: this.mapData.colours.borderColor,
        popupTemplate: (t, a) => {
          // Californians would take 5 unpaid days for their sick pets, which equates to $333.52 in lost income.
          let i = '<div class="stats-layer">';
          return (i +=
            '<div class="icon"></div><div class="stats">' +
            a.inhabitant +
            ' have incurred ' +
            a.statistic_1 +
            ' hours of sleep debt per week.</div></div></div>');
        },
      },
      fills: {
        defaultFill: this.mapData.colours.defaultFill,
      },
      data: this.mapData,
    });
    t.labels();
  }
  embedThis() {
    if (void 0 !== this.projectSettings) {
      /* tslint:disable:one-variable-per-declaration */
      const t = $('.embedder'),
        a = this.projectSettings.sourceUrl || window.location.href,
        i = this.projectSettings.brandUrl || window.location.hostname,
        e = this.projectSettings.fullSizeUrl || window.location.href,
        s = t
          .html()
          .replace(/\[URL\]/g, a)
          .replace(/\[SOURCE_URL\]/g, a)
          .replace(/\[BRAND_URL\]/g, i)
          .replace(/\[FULL_SIZE_URL\]/g, e)
          .replace(/\[BRAND\]/g, this.projectSettings.brandTitle)
          .replace(
            /\[FRAME_WIDTH\]/g,
            this.projectSettings.frameWidth
              ? this.projectSettings.frameWidth
              : '100%'
          )
          .replace(/\[FRAME_HEIGHT\]/g, this.projectSettings.frameHeight);
      t.html(s);
      const n = $('.site_link');
      n.each((_, item) => {
        // @ts-ignore
        $(item)
          // @ts-ignore
          .attr('href', this.projectSettings.brandUrl)
          .attr('target', '_blank');
      });
      t.on('click', function () {
        // @ts-ignore
        $(this).select();
      });
    } else {
      console.log('no project settings');
    }
  }
}
